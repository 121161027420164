import Typography from "../../../Components/Typography";
import TypographyList from "../../../Utils/Constants/TypographyList";

import * as SecondSectionStyles from "./styles";

import SocialAlerts from "../../../Components/SocialAlets";

import contextTexts from "./content.json";
import { AuthContextProps, useAuth } from "../../../Providers/Auth";
import { ArrowLeftIcon } from "../../../Assets/Icons";
import {
  ChartImage,
  ChatOptionImage,
  InsightsImage,
  ToDoListImage,
  WomanCellPhoneImage,
} from "../../../Assets/Images";

function SecondSection() {
  const { language }: AuthContextProps = useAuth();

  const contentTexts = contextTexts["pt-br"];

  return (
    <SecondSectionStyles.Container>
      <div>
        <SecondSectionStyles.Title>
          <Typography type={TypographyList.h1} lineSpace="small">
            {contentTexts.title}
          </Typography>
        </SecondSectionStyles.Title>
        <SecondSectionStyles.Row>
          <SecondSectionStyles.IconBox>
            <img
              src={WomanCellPhoneImage}
              alt="Pessoa com um telefone na mão"
            />
            <Typography type={TypographyList.body} lineSpace="small">
              {contentTexts.card1}
            </Typography>
          </SecondSectionStyles.IconBox>
          <div>
            <ArrowLeftIcon title="Seta para a direta" />
          </div>

          <SecondSectionStyles.IconBox>
            <img src={ToDoListImage} alt="Lista de tarefas com check" />
            <Typography type={TypographyList.body} lineSpace="small">
              {contentTexts.card2}
            </Typography>
          </SecondSectionStyles.IconBox>
          <div>
            <ArrowLeftIcon title="Seta para a direta" />
          </div>

          <SecondSectionStyles.IconBox>
            <img
              src={ChatOptionImage}
              alt="Exemplo de balão com mensagem de chat"
            />
            <Typography type={TypographyList.body} lineSpace="small">
              {contentTexts.card3}
            </Typography>
          </SecondSectionStyles.IconBox>
          <div>
            <ArrowLeftIcon title="Seta para a direta" />
          </div>

          <SecondSectionStyles.IconBox>
            <img
              src={ChartImage}
              alt="Tela com uma lupa vendo graficos de dados"
            />
            <Typography type={TypographyList.body} lineSpace="small">
              {contentTexts.card4}
            </Typography>
          </SecondSectionStyles.IconBox>
          <div>
            <ArrowLeftIcon title="Seta para a direta" />
          </div>
          <SecondSectionStyles.IconBox>
            <img
              src={InsightsImage}
              alt="Conjunto com ideias de tabelas e soluções"
            />
            <Typography type={TypographyList.body} lineSpace="small">
              {contentTexts.card5}
            </Typography>
          </SecondSectionStyles.IconBox>
        </SecondSectionStyles.Row>
      </div>
      <a
        href="https://api.whatsapp.com/send/?phone=5531999924020&text&type=phone_number&app_absent=0"
        target="_blank"
      >
        <SecondSectionStyles.Footer>
          <SocialAlerts
            message="Fale conosco AGORA pelo Whatsapp"
            type="whatsapp"
          />
        </SecondSectionStyles.Footer>
      </a>
    </SecondSectionStyles.Container>
  );
}

export default SecondSection;
