import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  height: 7vh;
  align-items: center;

  padding: 0.5rem 0.5rem;

  background-color: #f67201;
`;

export const IconBox = styled.div`
  margin-left: 1rem;

  > img {
    width: 10vw;
  }

  @media (max-width: 1560px) {
    > img {
      width: 9rem;
    }
  }
`;
