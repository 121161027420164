import styled from "styled-components";
import { WomanThinkingSVG } from "../../../Assets/Images";

export const Container = styled.div`
  width: 100vw;

  overflow: hidden;

  background-image: url(${WomanThinkingSVG});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (max-width: 480px) {
    background-position: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 96%;

  padding: 1rem 1rem 0rem 1rem;

  > img {
    width: 40vw;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  > p,
  > h1 {
    width: 100%;
    text-align: left;
    margin: 2rem 0;
  }

  @media (max-width: 1560px) {
    > p,
    > h1 {
      margin: 1.5rem 0;
      font-size: 10px;
      text-align: left;
    }
  }
  @media (max-width: 1310px) {
    > p,
    > h1 {
      margin: 1rem 0;
    }
  }
`;
