import React from "react";

import Logotype from "../Logotype";

import * as HeaderStyles from "./styles";

function Header() {
  return (
    <HeaderStyles.Container>
      <HeaderStyles.IconBox>
        <Logotype />
      </HeaderStyles.IconBox>
    </HeaderStyles.Container>
  );
}

export default Header;
