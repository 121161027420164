import Typography from "../../../Components/Typography";
import TypographyList from "../../../Utils/Constants/TypographyList";

import * as FirstSectionStyles from "./styles";

import SocialAlerts from "../../../Components/SocialAlets";

import contextTexts from "./content.json";
import { ArrowBackIcon } from "../../../Assets/Icons";
import { StressedWoman } from "../../../Assets/Images";

function FirstSection() {
  const contentTexts = contextTexts["pt-br"];

  return (
    <FirstSectionStyles.Container>
      <section>
          <Typography type={TypographyList.h1} lineSpace="large">
            {contentTexts.title}
          </Typography>
          <Typography type={TypographyList.h2} lineSpace="large">
            {contentTexts.subtitle1}
          </Typography>
          <Typography type={TypographyList.h2} lineSpace="large">
            {contentTexts.subtitle2}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.description}
          </Typography>
        <div>
          <Typography type={TypographyList.body}>
            {contentTexts.helpText}
          </Typography>
          <a
            href="https://api.whatsapp.com/send/?phone=5531999924020&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <SocialAlerts
              message="Fale conosco AGORA pelo Whatsapp"
              type="whatsapp"
            />
          </a>
        </div>
        <FirstSectionStyles.Footer
          onClick={() => window.scrollTo({ top: 750, behavior: "smooth" })}
        >
          <ArrowBackIcon title="Seta para baixo" />
        </FirstSectionStyles.Footer>
      </section>
    </FirstSectionStyles.Container>
  );
}

export default FirstSection;
