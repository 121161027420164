import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --white: #FFFFFF;
    --orange: #f67201;
    --gray: #C2C2C2
  }

  * {
    margin: 0;
    padding: 0;
  }
`;
