import styled from "styled-components";
import { StressedWoman } from "../../../Assets/Images";

export const Container = styled.div`
  width: 100%;

  background-image: url(${StressedWoman});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  overflow: hidden;

  > section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    width: 40%;
    min-width: 20rem;
    height: 100%;

    padding: 5% 0 0.5rem 5%;

    > h1,
    h2,
    p {
      margin: clamp(0.2rem, 4vw, 3.5rem) 0;
    }

    a {
      text-decoration: none;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;

  margin-top: 1rem;
  margin-bottom: 2vh;
  > svg {
    width: 12vw;
    min-width: 3rem;

    cursor: pointer;
  }
`;

export const StressedWomanImg = styled.img`
  width: 100%;

  @media (max-width: 480px) {
    img {
      width: 100%;
      height: 300px;
    }
  }
`;

export const ContainerImg = styled.div``;
