import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2rem);

  background-color: var(--orange);
  border-top: 1px solid var(--white);

  padding: 2rem 1rem;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CopyrightBox = styled.div`
  align-items: center;
`;

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: 4vw;

  > p {
    margin: 1rem 0;
  }
`;

export const ContactContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > svg + svg {
    margin-left: 2rem;
  }

  > svg {
    width: 3vw;
    height: fit-content;
  }

  @media (max-width: 1560px) {
    > svg {
      width: 3rem;
    }
  }
`;

export const IconBox = styled.div`
  background-color: transparent;

  cursor: pointer;
`;
