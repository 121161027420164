import { WomanThinking, RoomBluredImage } from "../../../Assets/Images";
import Typography from "../../../Components/Typography";
import TypographyList from "../../../Utils/Constants/TypographyList";
import * as ThirdSectionStyles from "./styles";

import contextTexts from "./content.json";

function ThirdSection() {
  const contentTexts = contextTexts["pt-br"];

  return (
    <ThirdSectionStyles.Container>
      <ThirdSectionStyles.ContentWrapper>
        <ThirdSectionStyles.Content>
          <Typography type={TypographyList.h1} lineSpace="large">
            {contentTexts.title}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section1}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section2}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section3}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section4}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section5}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section6}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section7}
          </Typography>
          <Typography type={TypographyList.body}>
            {contentTexts.section8}
          </Typography>
        </ThirdSectionStyles.Content>
      </ThirdSectionStyles.ContentWrapper>
    </ThirdSectionStyles.Container>
  );
}

export default ThirdSection;
