import Typography from "../../../Components/Typography";
import TypographyList from "../../../Utils/Constants/TypographyList";

import * as FooterSectionStyles from "./styles";

import { ReactComponent as IconUp } from "../../../Assets/Icons/icon-up.svg";
import Logotype from "../../../Components/Logotype";

import contextTexts from "./content.json";
import { InstagramIcon, WhatsappIcon } from "../../../Assets/Icons";

function FooterSection() {
  const contentTexts = contextTexts["pt-br"];

  return (
    <FooterSectionStyles.Container>
      <FooterSectionStyles.ContentBox>
        <FooterSectionStyles.CopyrightBox>
          <Logotype />
          <Typography type={TypographyList.body}>
            {contentTexts.copyright}
          </Typography>
        </FooterSectionStyles.CopyrightBox>
        <FooterSectionStyles.ContactBox>
          <Typography type={TypographyList.body} lineSpace="large">
            {contentTexts.contactText}
          </Typography>
          <a
            href="https://api.whatsapp.com/send/?phone=5531999924020&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <FooterSectionStyles.ContactContent>
              <WhatsappIcon title="Icone whatsapp - ir" />
            </FooterSectionStyles.ContactContent>
          </a>
        </FooterSectionStyles.ContactBox>
      </FooterSectionStyles.ContentBox>
      <FooterSectionStyles.IconBox
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <IconUp title="Ir para o topo da pagina" />
      </FooterSectionStyles.IconBox>
    </FooterSectionStyles.Container>
  );
}

export default FooterSection;
