import styled from "styled-components";

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  min-height: 60vh;

  background-color: var(--orange);

  padding: 1rem;

  a {
    text-decoration: none;
  }
`;

export const Title = styled.div`
  margin: 1rem 0;
  padding-bottom: 2rem;

  @media (max-width: 480px) {
    text-align: inherit;
    padding-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  height: 80%;

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }

  @media (max-width: 850px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    padding: 2rem 0;

    @media (max-width: 850px) {
      > svg {
        transform: rotate(90deg);
      }
    }
  }
`;

export const IconBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 20rem;

  > img {
    width: 13vw;
    max-width: 11rem;
    min-width: 10rem;
  }

  > p {
    text-align: center;
  }

  @media (max-width: 1250px) {
    width: 100%;
  }

  @media (max-width: 850px) {
    width: 98%;
    height: 15rem;
    padding: 1rem 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 95%;
  height: 10%;

  padding-bottom: 1rem;
  padding-right: 1rem;
`;
