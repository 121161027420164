import React from "react";
import BussinessImage from "../../Assets/Images/business-team.png";

import * as HomeStyles from "./styles";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import FooterSection from "./FooterSection";
import ThirdSection from "./ThirdSection";

function Home() {
  return (
    <HomeStyles.Container>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FooterSection />
    </HomeStyles.Container>
  );
}

export default Home;
