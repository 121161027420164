import { WhatsappIcon } from "../../Assets/Icons";
import TypographyList from "../../Utils/Constants/TypographyList";
import Typography from "../Typography";

import { Container } from "./styles";

interface SocialAlertsProps {
  message: string;
  type: "whatsapp";
}

function SocialAlerts({ message = "whatsapp", type }: SocialAlertsProps) {
  const SwitchSocialAlerts = ({ children }: { children: React.ReactNode }) => {
    switch (type) {
      case "whatsapp":
        return (
          <Container>
            <WhatsappIcon title="Icone do whatsapp" />

            {children}
          </Container>
        );
    }
  };
  return (
    <SwitchSocialAlerts>
      <Typography type={TypographyList.h6} lineSpace="small">
        {message}
      </Typography>
    </SwitchSocialAlerts>
  );
}

export default SocialAlerts;
