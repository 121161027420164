import React, { useState } from "react";

import Home from "./Pages/Home/index";
import { AuthProvider } from "./Providers/Auth";
import { GlobalStyles } from "./Styles/global";
import Header from "./Components/Header";

function App() {
  return (
    <AuthProvider>
      <GlobalStyles />
      <Header />
      <Home />
    </AuthProvider>
  );
}

export default App;
